<template>
  <section :id="id" :class="`body typo-rhythm ${cls} -${alignment}`">
    <p v-if="finalSuptitle" class="_suptitle" v-html="finalSuptitle"></p>

    <component :is="`h${headingLevel.level}`" v-if="title" class="_title">
      <div v-html="finalTitle"></div>
    </component>

    <p v-if="finalSubtitle" class="_subtitle" v-html="finalSubtitle"></p>

    <div v-if="finalLead" class="_lead" v-html="finalLead"></div>

    <div
      class="_text typo-rhythm typo-format"
      v-if="finalText"
      v-html="finalText"
    ></div>

    <!--    <div class="_spacer"></div>-->

    <nav class="_buttons" v-if="buttons?.length">
      <Button v-for="button in buttons" v-bind="button" :key="button.id" />
    </nav>
  </section>
</template>

<script setup lang="ts">
import { render } from 'datocms-structured-text-to-html-string'
import { isEmptyDocument } from 'datocms-structured-text-utils'
import { marked } from 'marked'
import Button from '~/components/ui/button/button.vue'
import './body.css'
import type { TBody } from './body.type.js'

const props = withDefaults(defineProps<TBody>(), {
  headingLevel: () => {
    return {
      level: 3,
    }
  },
})

const finalTitle = computed(() => marked.parseInline(props.title ?? '')),
  finalSuptitle = computed(() => marked.parseInline(props.suptitle ?? '')),
  finalSubtitle = computed(() => marked.parseInline(props.subtitle ?? '')),
  finalLead = computed(() => {
    return !isEmptyDocument(props.lead)
      ? render((<any>props.lead)?.value, {})
      : null
  }),
  finalText = computed(() => {
    return !isEmptyDocument(props.text)
      ? render((<any>props.text)?.value, {})
      : null
  })

const id = props.advanced?.id ?? ''
const cls = props.advanced?.class ?? ''
</script>
