<template>
  <Link v-bind="{
    ...link ?? {},
    ...link.link ?? {}
  }" :class="`button -${lnf} -${color}`" :aria-label="link.ariaLabel ?? link.title">
  {{ link.text }}
  </Link>
</template>
<script setup lang="ts">
import Link from '../link/link.vue';
import './button.css';
import type { TButton } from './button.type.js';

const props = withDefaults(defineProps<TButton>(), {
  lnf: 'solid',
  color: 'main',
})
</script>